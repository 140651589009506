
































import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { saveAs } from "file-saver";

export default defineComponent({
  components: {
    MInfo: () => import("@/components/molecules/report/m-info.vue"),
    MCriteria: () => import("@/components/molecules/report/m-criteria.vue"),
    MParticipantData: () =>
      import("@/components/molecules/report/m-participant-data.vue"),
    MRegistration: () =>
      import("@/components/molecules/report/m-registration.vue"),
    MAccommodation: () =>
      import("@/components/molecules/report/m-accommodation.vue"),
    MServices: () => import("@/components/molecules/report/m-services.vue"),
    MPayments: () => import("@/components/molecules/report/m-payments.vue"),
    MAbstracts: () => import("@/components/molecules/report/m-abstracts.vue"),
    MInvoice: () => import("@/components/molecules/report/m-invoice.vue"),
    MSurveys: () => import("@/components/molecules/report/m-surveys.vue"),
    MCustomFields: () =>
      import("@/components/molecules/report/m-custom-fields.vue"),
  },
  props: {
    participants: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const state = reactive({
      loading: false,
      info: {
        reportType: "",
      },
      expand: true,
      reportCriteria: [],
      dataReport: [],
      registrationReport: [],
      accommodationReport: [],
      servicesReport: [],
      paymentsReport: [],
      abstractsReport: [],
      invoiceReport: [],
      customFieldsReport: [],
      registrationFormTemplate: {} as any,
      secondaryRegistrationFormTemplate: {} as any,
    });

    const model = reactive({
      criteria: {
        participantGroup: {
          entity: "participantGroup",
          fieldName: "id",
          values: [],
          operation: "in",
        },
        participantState: {
          entity: "participant",
          fieldName: "state",
          values: ["participant"],
          operation: "in",
        },
        onlyWithAccommodation: {
          entity: "accommodation",
          fieldName: "id",
          values: [],
          operation: "isNotNull",
        },
        onlyWithServices: {
          entity: "services",
          fieldName: "id",
          values: [],
          operation: "isNotNull",
        },
        onlyWithAbstracts: {
          entity: "abstracts",
          fieldName: "id",
          values: [],
          operation: "isNotNull",
        },
        onlyPresent: {
          entity: "participant",
          fieldName: "wasPresent",
          values: [],
          operation: "is",
        },
      } as any,
      data: {
        state: {
          header: root.$tc("layout.misc.participantState"),
          key: "",
        },
        code: {
          header: root.$tc("layout.misc.participantCode"),
          key: "",
        },
        gender: {
          header: root.$tc("layout.misc.gender"),
          key: "",
        },
        firstName: {
          header: root.$tc("layout.misc.firstName"),
          key: "",
        },
        lastName: {
          header: root.$tc("layout.misc.lastName"),
          key: "",
        },
        degree: {
          header: root.$tc("layout.misc.degree"),
          key: "",
        },
        email: {
          header: root.$tc("layout.misc.email"),
          key: "",
        },
        phone: {
          header: root.$tc("layout.misc.phone"),
          key: "",
        },
        street: {
          header: root.$tc("layout.misc.street"),
          key: "",
        },
        postalCode: {
          header: root.$tc("layout.misc.postalCode"),
          key: "",
        },
        city: {
          header: root.$tc("layout.misc.city"),
          key: "",
        },
        country: {
          header: root.$tc("layout.misc.country"),
          key: "",
        },
        sponsor: {
          header: root.$tc("layout.misc.sponsor"),
          key: "",
        },
        note: {
          header: root.$tc("layout.misc.note"),
          key: "",
        },
        group: {
          header: root.$tc("layout.misc.group"),
          key: "",
        },
        specialization: {
          header: root.$tc("layout.misc.specialization"),
          key: "",
        },
        institutionName: {
          header: root.$tc("layout.misc.institutionName"),
          key: "",
        },
        institutionPosition: {
          header: root.$tc("layout.misc.institutionPosition"),
          key: "",
        },
        institutionCity: {
          header: root.$tc("layout.misc.institutionCity"),
          key: "",
        },
        institutionStreet: {
          header: root.$tc("layout.misc.institutionStreet"),
          key: "",
        },
        institutionPostalCode: {
          header: root.$tc("layout.misc.institutionPostalCode"),
          key: "",
        },
        wasPresenceDate: {
          header: root.$tc("layout.misc.presenceDate"),
          key: "",
        },
        receiveCertificateDate: {
          header: root.$tc("layout.misc.certificateReceiveDate"),
          key: "",
        },
        receiveConferenceMaterials: {
          header: root.$tc("layout.misc.receiveConferenceMaterials"),
          key: "",
        },
        receiveConferenceMaterialsDate: {
          header: root.$tc("layout.misc.receiveConferenceMaterialsDate"),
          key: "",
        },
        participantZoneLogin: {
          header: root.$tc("layout.misc.participantZoneLoginDate"),
          key: "",
        },
        usedDiscount: {
          header: root.$tc("layout.misc.usedDiscountCOde"),
          key: "",
        },
        identifiers: {
          header: root.$tc("layout.misc.identifiersCount"),
          key: "",
        },
      } as any,
      registration: {
        group: {
          header: root.$tc("panel.event.report.registrationGroup"),
          key: "",
        },
        type: {
          header: root.$tc("panel.event.report.registrationType"),
          key: "",
        },
        licenseNumber: {
          header: root.$tc("panel.event.report.licenseNumber"),
          key: "",
        },
        additionalField: {
          header: root.$tc("panel.event.report.additionalField"),
          key: "",
        },
        companion: {
          header: root.$tc("panel.event.report.companion"),
          key: "",
        },
        registrationDate: {
          header: root.$tc("panel.event.report.registrationDate"),
          key: "",
        },
        toPay: {
          header: root.$tc("panel.event.report.toPayRegistration"),
          key: "",
        },
        paid: {
          header: root.$tc("panel.event.report.paidRegistration"),
          key: "",
        },
        paymentDate: {
          header: root.$tc("panel.event.report.paymentDateRegistration"),
          key: "",
        },
        leftToPay: {
          header: root.$tc("panel.event.report.leftToPayRegistration"),
          key: "",
        },
        marketingConsent: {
          header: root.$tc("layout.misc.marketingConsent"),
          key: "",
        },
        emailOffersConsent: {
          header: root.$tc("layout.misc.emailOffersConsent"),
          key: "",
        },
        phoneOffersConsent: {
          header: root.$tc("layout.misc.phoneOffersConsent"),
          key: "",
        },
        termsConsent: {
          header: root.$tc("layout.misc.termsConsent"),
          key: "",
        },
      } as any,
      accommodation: {
        hotel: {
          header: root.$tc("panel.event.report.hotel"),
          key: "",
        },
        room: {
          header: root.$tc("panel.event.report.room"),
          key: "",
        },
        arrival: {
          header: root.$tc("panel.event.report.arrival"),
          key: "",
        },
        departure: {
          header: root.$tc("panel.event.report.departure"),
          key: "",
        },
        roommate: {
          header: root.$tc("panel.event.report.roommate"),
          key: "",
        },
        toPay: {
          header: root.$tc("panel.event.report.toPayAccommodation"),
          key: "",
        },
        paid: {
          header: root.$tc("panel.event.report.paidAccommodation"),
          key: "",
        },
        paymentDate: {
          header: root.$tc("panel.event.report.paymentDateAccommodation"),
          key: "",
        },
        leftToPay: {
          header: root.$tc("panel.event.report.leftToPayAccommodation"),
          key: "",
        },
      } as any,
      services: {
        group: {
          header: root.$tc("panel.event.report.group"),
          key: "",
        },
        serviceName: {
          header: root.$tc("panel.event.report.serviceName"),
          key: "",
        },
        quantity: {
          header: root.$tc("panel.event.report.quantity"),
          key: "",
        },
        toPay: {
          header: root.$tc("panel.event.report.toPayServices"),
          key: "",
        },
        paid: {
          header: root.$tc("panel.event.report.paidServices"),
          key: "",
        },
        paymentDate: {
          header: root.$tc("panel.event.report.paymentDateServices"),
          key: "",
        },
        leftToPay: {
          header: root.$tc("panel.event.report.leftToPayServices"),
          key: "",
        },
      } as any,
      payments: {
        toPay: {
          header: root.$tc("panel.event.report.toPay"),
          key: "",
        },
        paid: {
          header: root.$tc("panel.event.report.paid"),
          key: "",
        },
        leftToPay: {
          header: root.$tc("panel.event.report.leftToPay"),
          key: "",
        },
        paymentType: {
          header: root.$tc("panel.event.report.paymentType"),
          key: "",
        },
        paymentDate: {
          header: root.$tc("panel.event.report.paymentDate"),
          key: "",
        },
        paymentAmount: {
          header: root.$tc("panel.event.report.ePaymentAmount"),
          key: "",
        },
        transactionId: {
          header: root.$tc("panel.event.report.transactionNumber"),
          key: "",
        },
      } as any,
      abstracts: {
        title: {
          header: root.$tc("panel.event.report.abstractTitle"),
          key: "",
        },
        keywords: {
          header: root.$tc("panel.event.report.keyWords"),
          key: "",
        },
        languageVersion: {
          header: root.$tc("panel.event.report.languageVersion"),
          key: "",
        },
        type: {
          header: root.$tc("layout.misc.type"),
          key: "",
        },
        sendDate: {
          header: root.$tc("panel.event.report.sendDate"),
          key: "",
        },
        reviewerFirstName: {
          header: root.$tc("panel.event.report.reviewerFirstName"),
          key: "",
        },
        reviewerLastName: {
          header: root.$tc("panel.event.report.reviewerLastName"),
          key: "",
        },
        sessionTopic: {
          header: root.$tc("panel.event.report.sessionTopic"),
          key: "",
        },
        averageRating: {
          header: root.$tc("panel.event.report.averageRating"),
          key: "",
        },
        abstractNumber: {
          header: root.$tc("panel.event.report.abstractNumber"),
          key: "",
        },
        senderFirstName: {
          header: root.$tc("panel.event.report.senderFirstName"),
          key: "",
        },
        senderLastName: {
          header: root.$tc("panel.event.report.senderLastName"),
          key: "",
        },
        authorFirstName: {
          header: root.$tc("panel.event.report.authorFirstName"),
          key: "",
        },
        authorLastName: {
          header: root.$tc("panel.event.report.authorLastName"),
          key: "",
        },
        authorsEmail: {
          header: root.$tc("panel.event.report.authorsEmail"),
          key: "",
        },
        authorsAddress: {
          header: root.$tc("panel.event.report.authorsAddress"),
          key: "",
        },
        authorsInstitution: {
          header: root.$tc("panel.event.report.authorsInstitution"),
          key: "",
        },
        symposium: {
          header: root.$tc("panel.event.report.symposium"),
          key: "",
        },
        abstractShort: {
          header: root.$tc("panel.event.report.abstractShort"),
          key: "",
        },
      } as any,
      invoice: {
        purchaserType: {
          header: root.$tc("panel.event.report.purchaserType"),
          key: "",
        },
        purchaserName: {
          header: root.$tc("panel.event.report.purchaserName"),
          key: "",
        },
        street: {
          header: root.$tc("layout.misc.street"),
          key: "",
        },
        postalCode: {
          header: root.$tc("layout.misc.postalCode"),
          key: "",
        },
        city: {
          header: root.$tc("layout.misc.city"),
          key: "",
        },
        nip: {
          header: root.$tc("panel.event.report.nip"),
          key: "",
        },
        sendEmail: {
          header: root.$tc("panel.event.report.sendEmail"),
          key: "",
        },
        invoiceNumber: {
          header: root.$tc("panel.event.report.invoiceNumber"),
          key: "",
        },
        correctionNumber: {
          header: root.$tc("panel.event.report.correctionNumber"),
          key: "",
        },
        adnotations: {
          header: root.$tc("panel.event.report.adnotations"),
          key: "",
        },
      } as any,
      surveys: {
        surveysIds: [],
      },
      customFields: { primaryFields: [], secondaryFields: [] } as any,
    });

    const fetchEvent = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;
      axiosInstance
        .get(`event/${root.$route.params.id}`)
        .then(({ data: { event } }) => {
          state.registrationFormTemplate = event.registrationFormTemplate ?? {};
          state.secondaryRegistrationFormTemplate =
            event.secondaryRegistrationFormTemplate ?? {};
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          state.loading = false;
        });
    };

    onMounted(fetchEvent);

    const regularFields = [
      "firstName",
      "lastName",
      "email",
      "repeatEmail",
      "phonePrefix",
      "phoneNumber",
      "gender",
      "degree",
      "specializations",
      "licenseNumber",
      "studentNumber",
      "sponsor",
      "country",
      "addressCity",
      "addressPostalCode",
      "addressStreet",
      "institutionName",
      "institutionPosition",
      "institutionCity",
      "institutionPostalCode",
      "institutionStreet",
      "companion",
      "sessionTopicsIds",
    ];

    const primaryCustomFields = computed(() => {
      if (state.registrationFormTemplate) {
        if (
          state.registrationFormTemplate.groups &&
          state.registrationFormTemplate.groups.length
        ) {
          const groups = state.registrationFormTemplate?.groups.filter(
            (el: any) => el.type === "fields",
          );
          const tempArray = groups
            .map((el: any) => el.fields)
            .flat()
            .map((el: any) => ({
              type: el.type,
              label: el.label,
              name: el.name,
            }));

          const elementsToDeleteSet = new Set(regularFields);

          const fieldsArray = tempArray.filter((el: any) => {
            return !elementsToDeleteSet.has(el.type);
          });
          fieldsArray.forEach((el: any) => {
            model.customFields.primaryFields.push({
              header: el.label,
              key: ``,
              name: el.name,
            });
          });

          return fieldsArray;
        }
      } else return [];
    });

    const secondaryCustomFields = computed(() => {
      if (state.secondaryRegistrationFormTemplate) {
        if (
          state.secondaryRegistrationFormTemplate.groups &&
          state.secondaryRegistrationFormTemplate.groups.length
        ) {
          const groups = state.secondaryRegistrationFormTemplate?.groups.filter(
            (el: any) => el.type === "fields",
          );
          const tempArray = groups
            .map((el: any) => el.fields)
            .flat()
            .map((el: any) => ({
              type: el.type,
              label: el.label,
              name: el.name,
            }));

          const elementsToDeleteSet = new Set(regularFields);

          const fieldsArray = tempArray.filter((el: any) => {
            return !elementsToDeleteSet.has(el.type);
          });
          fieldsArray.forEach((el: any) => {
            model.customFields.secondaryFields.push({
              header: el.label,
              key: ``,
              name: el.name,
            });
          });

          return fieldsArray;
        }
      } else return [];
    });

    watch(
      () => model.criteria,
      () => {
        const tempArray: any = Object.keys(model.criteria as any).map(
          (e) => model.criteria[e],
        );
        state.reportCriteria = tempArray.filter(
          (el: any) => el.values && el.values.length,
        );
      },
      { deep: true },
    );

    watch(
      () => model.data,
      () => {
        const tempArray: any = Object.keys(model.data as any).map(
          (e) => model.data[e],
        );
        state.dataReport = tempArray.filter((el: any) => el.key);
      },
      { deep: true },
    );
    watch(
      () => model.registration,
      () => {
        const tempArray: any = Object.keys(model.registration as any).map(
          (e) => model.registration[e],
        );
        state.registrationReport = tempArray.filter((el: any) => el.key);
      },
      { deep: true },
    );
    watch(
      () => model.accommodation,
      () => {
        const tempArray: any = Object.keys(model.accommodation as any).map(
          (e) => model.accommodation[e],
        );
        state.accommodationReport = tempArray.filter((el: any) => el.key);
      },
      { deep: true },
    );
    watch(
      () => model.services,
      () => {
        const tempArray: any = Object.keys(model.services as any).map(
          (e) => model.services[e],
        );
        state.servicesReport = tempArray.filter((el: any) => el.key);
      },
      { deep: true },
    );
    watch(
      () => model.payments,
      () => {
        const tempArray: any = Object.keys(model.payments as any).map(
          (e) => model.payments[e],
        );
        state.paymentsReport = tempArray.filter((el: any) => el.key);
      },
      { deep: true },
    );
    watch(
      () => model.abstracts,
      () => {
        const tempArray: any = Object.keys(model.abstracts as any).map(
          (e) => model.abstracts[e],
        );
        state.abstractsReport = tempArray.filter((el: any) => el.key);
      },
      { deep: true },
    );
    watch(
      () => model.invoice,
      () => {
        const tempArray: any = Object.keys(model.invoice as any).map(
          (e) => model.invoice[e],
        );
        state.invoiceReport = tempArray.filter((el: any) => el.key);
      },
      { deep: true },
    );
    watch(
      () => model.customFields,
      () => {
        const tempArray: any = Object.keys(model.customFields as any)
          .map((e) => model.customFields[e])
          .flat();
        state.customFieldsReport = tempArray
          .filter((el: any) => el.key)
          .map((el: any) => ({ header: el.header, key: el.key }));
      },
      { deep: true },
    );

    const resetAllValues = () => {
      model.criteria.participantGroup.values = [];
      model.criteria.participantState.values = [];
      model.criteria.onlyWithAccommodation.values = [];
      model.criteria.onlyWithServices.values = [];
      model.criteria.onlyWithAbstracts.values = [];
      model.criteria.onlyPresent.values = [];

      model.data.state.key = "";
      model.data.code.key = "";
      model.data.gender.key = "";
      model.data.firstName.key = "";
      model.data.lastName.key = "";
      model.data.degree.key = "";
      model.data.email.key = "";
      model.data.phone.key = "";
      model.data.street.key = "";
      model.data.postalCode.key = "";
      model.data.country.key = "";
      model.data.sponsor.key = "";
      model.data.note.key = "";
      model.data.group.key = "";
      model.data.specialization.key = "";
      model.data.institutionName.key = "";
      model.data.institutionPosition.key = "";
      model.data.institutionCity.key = "";
      model.data.institutionStreet.key = "";
      model.data.institutionPostalCode.key = "";
      model.data.wasPresenceDate.key = "";
      model.data.receiveCertificateDate.key = "";
      model.data.receiveConferenceMaterials.key = "";
      model.data.participantZoneLogin.key = "";
      model.data.usedDiscount.key = "";
      // model.data.identifiers.key = "";

      model.registration.group.key = "";
      model.registration.type.key = "";
      model.registration.licenseNumber.key = "";
      model.registration.additionalField.key = "";
      model.registration.companion.key = "";
      model.registration.registrationDate.key = "";
      model.registration.toPay.key = "";
      model.registration.paid.key = "";
      model.registration.paymentDate.key = "";
      model.registration.leftToPay.key = "";
      model.registration.marketingConsent.key = "";
      model.registration.emailOffersConsent.key = "";
      model.registration.phoneOffersConsent.key = "";
      model.registration.termsConsent.key = "";

      model.accommodation.hotel.key = "";
      model.accommodation.room.key = "";
      model.accommodation.arrival.key = "";
      model.accommodation.departure.key = "";
      model.accommodation.roommate.key = "";
      model.accommodation.toPay.key = "";
      model.accommodation.paid.key = "";
      model.accommodation.paymentDate.key = "";
      model.accommodation.leftToPay.key = "";

      model.services.group.key = "";
      model.services.serviceName.key = "";
      model.services.quantity.key = "";
      model.services.toPay.key = "";
      model.services.paid.key = "";
      model.services.paymentDate.key = "";
      model.services.leftToPay.key = "";

      model.payments.toPay.key = "";
      model.payments.paid.key = "";
      model.payments.leftToPay.key = "";
      model.payments.paymentType.key = "";
      model.payments.paymentDate.key = "";
      model.payments.paymentAmount.key = "";
      model.payments.transactionId.key = "";

      model.abstracts.title.key = "";
      model.abstracts.keywords.key = "";
      model.abstracts.languageVersion.key = "";
      model.abstracts.type.key = "";
      model.abstracts.sendDate.key = "";

      model.invoice.purchaserType.key = "";
      model.invoice.purchaserName.key = "";
      model.invoice.street.key = "";
      model.invoice.postalCode.key = "";
      model.invoice.city.key = "";
      model.invoice.nip.key = "";
      model.invoice.sendEmail.key = "";
      model.invoice.invoiceNumber.key = "";
      model.invoice.correctionNumber.key = "";
      model.invoice.adnotations.key = "";
    };

    const selectReportType = () => {
      if (state.info.reportType === "general") {
        resetAllValues();
        model.criteria.participantState.values = ["participant"];
        model.data.lastName.key = "lastName";
        model.data.firstName.key = "firstName";
        model.data.group.key = "participantGroups.:name";
        model.data.degree.key = "degree";
        model.data.phone.key = "phoneNumber";
        model.data.email.key = "email";
        model.data.institutionName.key = "institutionName";
        model.data.institutionStreet.key = "institutionStreet";
        model.data.institutionPostalCode.key = "institutionPostalCode";
        model.data.institutionCity.key = "institutionCity";
        model.data.sponsor.key = "sponsor";
        model.registration.registrationDate.key = "createdAt";
      } else if (state.info.reportType === "accommodation") {
        resetAllValues();
        model.criteria.participantState.values = ["participant"];
        model.criteria.onlyWithAccommodation.values = ["withAccommodation"];
        model.data.lastName.key = "lastName";
        model.data.firstName.key = "firstName";
        model.accommodation.hotel.key =
          "accommodation.room.hotel.location.name";
        model.accommodation.room.key = "accommodation.room.name";
        model.accommodation.arrival.key = "accommodation.from";
        model.accommodation.departure.key = "accommodation.to";
        model.accommodation.roommate.key = "accommodation.roommate";
        model.accommodation.toPay.key = "accommodation.toPay";
        model.accommodation.paid.key = "accommodation.paid";
        model.accommodation.paymentDate.key = "accommodation.paymentDate";
        model.accommodation.leftToPay.key = "accommodation.leftToPay";
      } else if (state.info.reportType === "services") {
        resetAllValues();
        model.criteria.participantState.values = ["participant"];
        model.criteria.onlyWithServices.values = ["withServices"];
        model.data.lastName.key = "lastName";
        model.data.firstName.key = "firstName";
        model.services.group.key = "services.:service.group.name";
        model.services.serviceName.key = "services.:service.name";
        model.services.quantity.key = "services.:selectedAmount";
        model.services.toPay.key = "services.:toPay";
        model.services.paid.key = "services.:paid";
        model.services.paymentDate.key = "services.:paymentDate";
        model.services.leftToPay.key = "services.:leftToPay";
      } else if (state.info.reportType === "abstracts") {
        resetAllValues();
        model.criteria.participantState.values = ["participant"];
        model.criteria.onlyWithAbstracts.values = ["withAbstract"];
        model.data.lastName.key = "lastName";
        model.data.firstName.key = "firstName";
        model.abstracts.title.key = "abstracts.:title";
        model.abstracts.keywords.key = "abstracts.:keywords.value";
        model.abstracts.languageVersion.key = "abstracts.:languageVersion";
        model.abstracts.type.key = "abstracts.:type";
        model.abstracts.sendDate.key = "abstracts.:createdAt";
      } else if (state.info.reportType === "invoice") {
        resetAllValues();
        model.criteria.participantState.values = ["participant"];
        model.data.lastName.key = "lastName";
        model.data.firstName.key = "firstName";
        model.invoice.purchaserType.key = "invoicePurchaser";
        model.invoice.purchaserName.key = "invoiceName";
        model.invoice.street.key = "invoiceStreet";
        model.invoice.postalCode.key = "invoicePostalCode";
        model.invoice.city.key = "invoiceCity";
        model.invoice.nip.key = "invoiceVatNumber";
        model.invoice.sendEmail.key = "invoiceEmail";
        model.invoice.invoiceNumber.key = "invoiceNumber";
        model.invoice.correctionNumber.key = "invoiceCorrection";
        model.invoice.adnotations.key = "invoiceNote";
      } else if (state.info.reportType === "payments") {
        resetAllValues();
        model.criteria.participantState.values = ["participant"];
        model.data.lastName.key = "lastName";
        model.data.firstName.key = "firstName";
        model.registration.toPay.key = "participantRegistrationFeeTerm.toPay";
        model.registration.paid.key = "participantRegistrationFeeTerm.paid";
        model.registration.leftToPay.key =
          "participantRegistrationFeeTerm.leftToPay";
        model.accommodation.toPay.key = "accommodation.toPay";
        model.accommodation.paid.key = "accommodation.paid";
        model.accommodation.leftToPay.key = "accommodation.leftToPay";
        model.services.toPay.key = "services.:toPay";
        model.services.paid.key = "services.:paid";
        model.services.leftToPay.key = "services.:leftToPay";
      }
    };

    watch(
      () => state.info.reportType,
      () => selectReportType(),
    );

    const getReport = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      state.loading = true;

      const participants =
        props.participants && props.participants.length
          ? {
              entity: "participant",
              fieldName: "id",
              values: props.participants.map((el: any) => el.id),
              operation: "in",
            }
          : [];

      const data = {
        columns: [
          {
            header: "Lp.",
            key: "lp",
          },
        ].concat(
          state.dataReport,
          state.registrationReport,
          state.accommodationReport,
          state.servicesReport,
          state.paymentsReport,
          state.abstractsReport,
          state.invoiceReport,
          state.customFieldsReport,
        ),
        criteria: [
          {
            entity: "event",
            fieldName: "id",
            values: [root.$route.params.id],
            operation: "in",
          },
        ].concat(state.reportCriteria, participants),
        surveysIds: model.surveys.surveysIds || [],
      };

      axiosInstance
        .post(`event/${root.$route.params.id}/report/advanced`, data, {
          responseType: "blob",
        })
        .then((response) => {
          saveAs(response.data, "raport.xlsx");
          response.data;
        })
        .catch((error) => console.log(error))
        .finally(() => (state.loading = false));
    };

    return {
      state,
      model,
      getReport,
      primaryCustomFields,
      secondaryCustomFields,
    };
  },
});
